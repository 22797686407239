/**
 * 定义组件的事件
 */
export default {
  "tdt-map": [
    "click",
    "dblclick",
    "contextmenu",
    "mousemove",
    "mouseover",
    "mouseout",
    "movestart",
    "move",
    "moveend",
    "zoomstart",
    "zoomend",
    "addoverlay",
    "removeoverlay",
    "addcontrol",
    "removecontrol",
    "clearoverlays",
    "dragstart",
    "drag",
    "dragend",
    "layeradd",
    "layerremove",
    "load",
    "resize",
    "levels",
    "touchstart",
    "touchmove",
    "touchend",
    "longpress"
  ],
  "tdt-tilelayer": ["loading", "load", "tileloadstart", "tileload", "tileunload", "tileerror"],
  "tdt-tilelayer-tdt": ["loading", "load", "tileloadstart", "tileload", "tileunload", "tileerror"],
  "tdt-tilelayer-wms": ["loading", "load", "tileloadstart", "tileload", "tileunload", "tileerror"],
  "tdt-label": ["click", "dblclick", "mousedown", "mouseup", "mouseout"],
  "tdt-marker": [
    "click",
    "dblclick",
    "mousedown",
    "mouseup",
    "mouseout",
    "mouseover",
    "dragstart",
    "drag",
    "dragend",
    "remove"
  ],
  "tdt-infowindow": ["close", "open", "clickclose"],
  "tdt-polyline": ["click", "dblclick", "mousedown", "mouseup", "mouseout", "mouseover", "remove"],
  "tdt-polygon": ["click", "dblclick", "mousedown", "mouseup", "mouseout", "mouseover", "remove"],
  "tdt-rectangle": ["click", "dblclick", "mousedown", "mouseup", "mouseout", "mouseover", "remove"],
  "tdt-circle": ["click", "dblclick", "mousedown", "mouseup", "mouseout", "mouseover", "remove"],
  "tdt-marker-cluster": [
    "click",
    "dblclick",
    "mousedown",
    "mouseup",
    "mouseout",
    "mouseover",
    "dragstart",
    "drag",
    "dragend",
    "remove"
  ]
};
