<template>
  <div>
    <el-row
      :gutter="24"
      class="index-top"
      style="margin-bottom: 0px; height: 100%"
    >
      <el-col :span="24" class="mb15 mapbox" style="height: 100%">
        <div id="map">
          <test-map ref="mapcontainer"></test-map>
          <!-- <Hz :totalXmxz="totalXmxz" :totalJsxz="totalJsxz" :totalXzqh="totalXzqh" /> -->
        </div>
        <div class="layer-content" style="overflow-y: auto">
          <div class="navbar mb15">
            <el-link class="active" :underline="false">固定资产 </el-link>
          </div>
          <div class="layerbox">
            <el-tree
              :data="data"
              show-checkbox
              node-key="id"
              @check-change="handleCheckChange"
              :default-expanded-keys="[50, 5003]"
              :default-checked-keys="[100101, 100102, 100104, 100105, 100106,100107,100108]"
              :props="defaultProps"
            >
            </el-tree>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import TestMap from "@/components/maps.vue";
export default {
  components: { TestMap },
  name: "Index",
  data() {
    return {
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
      if (data.layerid == undefined) return;
      if (data.tile == 1) {
        if (!checked) {
          this.$refs.mapcontainer.removeLayer(data.id);
        } else {
          this.$refs.mapcontainer.addTileLayer(data.id, data.server,data.zindex);
        }
        return;
      }
      if (!checked) {
        this.$refs.mapcontainer.removeLayer(data.id);
      } else {
        this.$refs.mapcontainer.addLayer(data.id);
      }
    },
    handleNodeClick(data) {
      console.log(data);
    },
    getData() {
      this.data = [
        {
          id: 10,
          label: "公路数据",
          children: [
            {
              id: 1001,
              label: "公路",
              children: [
                {
                  id: 100101,
                  label: "国道高速",
                  layerid: 1,
                  tile: 1,
                  server: "zc_gdgs1",
                  zindex:999
                },
                {
                  id: 100102,
                  label: "省道高速",
                  layerid: 0,
                  tile: 1,
                  server: "zc_sdgs",
                   zindex:998
                },
                {
                  id: 100104,
                  label: "国道",
                  layerid: 0,
                  tile: 1,
                  server: "zc_gd",
                   zindex:997
                },
                {
                  id: 100105,
                  label: "省道",
                  layerid: 0,
                  tile: 1,
                  server: "zc_sd",
                   zindex:996
                },
                {
                  id: 100106,
                  label: "县道",
                  layerid: 0,
                  tile: 1,
                  server: "zc_xd",
                   zindex:995
                },
                {
                  id: 100107,
                  label: "乡道",
                  layerid: 0,
                  tile: 1,
                  server: "zc_yd",
                   zindex:994
                },
                { id: 100108, label: "专用公路", layerid: 0,
                  tile: 1,
                  server: "zc_zygl1", zindex:993 },
                { id: 100109, label: "村道" },
                { id: 100110, label: "桥梁" },
                { id: 100111, label: "隧道" },
                { id: 100112, label: "涵洞" },
              ],
            },
            {
              id: 1002,
              label: "沿线附属设施",
              disabled: true,
              children: [
                { id: 100201, label: "服务区", disabled: true },
                { id: 100202, label: "出入口", disabled: true },
                { id: 100203, label: "收费站", disabled: true },
                { id: 100204, label: "避险车道", disabled: true },
                { id: 100205, label: "交通量观测站", disabled: true },
                { id: 100206, label: "标志牌", disabled: true },
                { id: 100207, label: "加油站", disabled: true },
                { id: 100208, label: "气象监测器", disabled: true },
                { id: 100209, label: "能见度仪", disabled: true },
                { id: 100210, label: "视频摄像机点", disabled: true },
                { id: 100211, label: "交通量调查设备", disabled: true },
                { id: 100212, label: "可变情报板", disabled: true },
                { id: 100213, label: "治超站", disabled: true },
                { id: 100214, label: "养护机构", disabled: true },
                { id: 100215, label: "里程碑", disabled: true },
              ],
            },
            {
              id: 1003,
              label: "路网风险隐患",
              disabled: true,
              children: [
                { id: 100301, label: "危险路段", disabled: true },
                { id: 100302, label: "危桥", disabled: true },
                { id: 100303, label: "危险隧道", disabled: true },
                { id: 100304, label: "交通事故频发点", disabled: true },
                { id: 100305, label: "交通事故频发路段", disabled: true },
                { id: 100306, label: "地质灾害点", disabled: true },
              ],
            },
          ],
        },
        {
          id: 20,
          label: "道路运输数据",
          disabled: true,
          children: [
            { id: 2001, label: "道路运输管理机构", disabled: true },
            { id: 2002, label: "重点客货运枢纽", disabled: true },
            { id: 2003, label: "物流园区及站场", disabled: true },
            { id: 2004, label: "机动车驾驶培训机构", disabled: true },
            { id: 2005, label: "客运班线（跨省高速）", disabled: true },
            {
              id: 2006,
              label: "货运装载源头企业（重点监管企业）",
              disabled: true,
            },
            {
              id: 2007,
              label: "城市客运信息（出租企业、售票班点等）",
              disabled: true,
            },
            { id: 2008, label: "车辆维修检测站", disabled: true },
          ],
        },
        {
          id: 30,
          label: "水路数据",
          disabled: true,
          children: [
            {
              id: 3001,
              label: "电子航道图",
              disabled: true,
              children: [
                { id: 300101, label: "内河航道", disabled: true },
                { id: 300102, label: "船闸", disabled: true },
                { id: 300103, label: "航标", disabled: true },
                { id: 300104, label: "水上服务区", disabled: true },
                { id: 300105, label: "航道管理单位", disabled: true },
                { id: 300106, label: "水上搜救中心", disabled: true },
                { id: 300107, label: "海事渡口", disabled: true },
                { id: 300108, label: "海事签证点", disabled: true },
                { id: 300109, label: "海事管理单位", disabled: true },
                { id: 300110, label: "浮桥", disabled: true },
                { id: 300111, label: "水库", disabled: true },
                { id: 300112, label: "港口", disabled: true },
                { id: 300113, label: "港区", disabled: true },
                { id: 300114, label: "候泊区", disabled: true },
                { id: 300115, label: "疏港公路", disabled: true },
                { id: 300116, label: "等深线", disabled: true },
                { id: 300117, label: "大型码头泊位", disabled: true },
                { id: 300118, label: "小型码头泊位", disabled: true },
                { id: 300119, label: "锚地", disabled: true },
                { id: 300120, label: "仓库堆场", disabled: true },
                { id: 300121, label: "港口管理单位", disabled: true },
              ],
            },
            {
              id: 3002,
              label: "航道库区",
              disabled: true,
              children: [
                { id: 300201, label: "水位水情监测设备", disabled: true },
                { id: 300202, label: "气象监测设备", disabled: true },
                { id: 300203, label: "摄像机", disabled: true },
                { id: 300204, label: "可变情报板", disabled: true },
              ],
            },
            {
              id: 3003,
              label: "水网风险隐患",
              disabled: true,
              children: [
                { id: 300301, label: "危险航段", disabled: true },
                { id: 300301, label: "水上交通事故多发点", disabled: true },
                { id: 300301, label: "地质灾害点", disabled: true },
              ],
            },
            { id: 3004, label: "水网实景三维地图", disabled: true },
            { id: 3005, label: "3D建模地图", disabled: true },
          ],
        },
        {
          id: 40,
          label: "动态数据",
          disabled: true,
          children: [
            { id: 4001, label: "两客一危重点运营车辆", disabled: true },
            { id: 4002, label: "出租车辆", disabled: true },
            { id: 4003, label: "路政管理车辆", disabled: true },
            { id: 4004, label: "运政管理车辆", disabled: true },
            { id: 4005, label: "养护巡查车辆", disabled: true },
            { id: 4006, label: "四客一危重点船舶动态数据", disabled: true },
            { id: 4007, label: "随手拍", disabled: true },
          ],
        },
        {
          id: 50,
          label: "专题数据",
          children: [
            {
              id: 5001,
              label: "现状",
              disabled: true,
              children: [
                { id: 500101, label: "行政等级专题", disabled: true },
                { id: 500102, label: "技术等级专题", disabled: true },
                { id: 500103, label: "路面类型站提", disabled: true },
                { id: 500104, label: "永久基本农田", disabled: true },
                { id: 500105, label: "生态保护红线", disabled: true },
                { id: 500106, label: "图片", disabled: true },
              ],
            },
            {
              id: 5002,
              label: "专项",
              disabled: true,
              children: [
                { id: 500201, label: "乡镇通三级以上", disabled: true },
                { id: 500202, label: "自然村通硬化", disabled: true },
                { id: 500203, label: "灾毁", disabled: true },
                {
                  id: 500204,
                  label: "综合交通网络现状图",
                  layerid: 13,
                  title: 0,
                },
                {
                  id: 500205,
                  label: "综合交通网络规划图（2025)",
                  layerid: 14,
                  title: 0,
                },
                {
                  id: 500206,
                  label: "综合交通运输通道布局图",
                  layerid: 15,
                  title: 0,
                },
                {
                  id: 500207,
                  label: "铁路“十四五”建设项目图",
                  layerid: 16,
                  title: 0,
                },
                {
                  id: 500208,
                  label: "高速公路“十四五”建设项目图",
                  layerid: 17,
                  title: 0,
                },
                {
                  id: 500209,
                  label: "水运“十四五”建设项目图",
                  layerid: 18,
                  title: 0,
                },
                {
                  id: 500210,
                  label: "运输机场“十四五”建设项目图",
                  layerid: 19,
                  title: 0,
                },
                {
                  id: 500211,
                  label: "综合交通客运枢纽“十四五”建设项目图",
                  layerid: 20,
                  title: 0,
                },
                {
                  id: 500212,
                  label: "综合交通货运枢纽“十四五”建设项目图",
                  layerid: 21,
                  title: 0,
                },
              ],
            },
            {
              id: 5003,
              label: "规划",
              children: [
                {
                  id: 500301,
                  label: "高速",
                  children: [
                    { id: 50030101, label: "三年行动", layerid: 2, title: 0 },
                    {
                      id: 50030102,
                      label: "2023-2025年行动",
                      layerid: 3,
                      title: 0,
                    },
                    { id: 50030103, label: "在建", layerid: 4, title: 0 },
                    { id: 50030104, label: "新开工", layerid: 5, title: 0 },
                  ],
                },
                {
                  id: 500302,
                  label: "普通国道",
                  children: [
                    {
                      id: 50030201,
                      label: "交通部项目库",
                      layerid: 6,
                      title: 0,
                    },
                    { id: 50030202, label: "十四五谋划", layerid: 7, title: 0 },
                  ],
                },
                {
                  id: 500303,
                  label: "普通省道",
                  children: [
                    {
                      id: 50030301,
                      label: "十四五正选",
                      layerid: 10,
                      title: 0,
                    },
                    { id: 50030302, label: "十四五备选", layerid: 9, title: 0 },
                    { id: 50030303, label: "十五五项目", layerid: 8, title: 0 },
                  ],
                },
                { id: 500304, label: "标记图层" },
                {
                  id: 500305,
                  label: "铁科高速",
                  children: [
                    { id: 50030501, label: "方延段", layerid: 11, title: 0 },
                    { id: 50030502, label: "尚五段", layerid: 12, title: 0 },
                  ],
                },
              ],
            },
            {
              id: 5004,
              label: "列养",
              children: [
                {
                  id: 500401,
                  label: "国道",
                  children: [
                    { id: 50040101, label: "列养" },
                    { id: 50040102, label: "未列养" },
                    { id: 50040103, label: "砂石" },
                  ],
                },
                {
                  id: 500402,
                  label: "省道",
                  children: [
                    { id: 50040201, label: "列养" },
                    { id: 50040202, label: "未列养" },
                    { id: 50040203, label: "砂石" },
                  ],
                },
              ],
            },
          ],
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.mapbox {
  position: relative;
}
#map {
  width: 100%;
  height: 100vh;
}
.layer-content {
  width: 260px;
  background: #fff;
  height: 100vh;
  position: absolute;
  padding: 20px 15px;
  top: 0;
  left: 0px;
  bottom: 0;
  z-index: 999999;
  -webkit-box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
  .title2 {
    border-bottom: 1px #eee solid;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}
</style>