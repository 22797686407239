<template>
    <tdt-map :center="center" :zoom="zoom" :controls="controls" :mapStyle="mapStyle" @init="mapInit">
    </tdt-map>
</template>

<script>
import { TdtMap, initApiLoader } from "../../packages";
initApiLoader({
    v: "4.0",
    tk: "93724b915d1898d946ca7dc7b765dda5",
});

export default {
    name: "test-map",
    components: { TdtMap },
    data() {
        return {
            center: [126.538161, 45.811034],
            zoom: 11,
            map: null,
            layers: [],
            // mapStyle: "black",
            mapStyle: "",
            controls: [
                "zoom",
                "copyright",
                {
                    name: "scale",
                    position: "bottomright",
                },
                {
                    name: "mapType",
                    mapTypes: [
                        {
                            title: "地图",
                            icon: "http://api.tianditu.gov.cn/v4.0/image/map/maptype/vector.png",
                            layer: "TMAP_NORMAL_MAP",
                        },
                        {
                            title: "卫星混合",
                            icon: "http://api.tianditu.gov.cn/v4.0/image/map/maptype/satellitepoi.png",
                            layer: "TMAP_HYBRID_MAP",
                        },
                        {
                            title: "地形混合",
                            icon: " http://api.tianditu.gov.cn/v4.0/image/map/maptype/terrainpoi.png",
                            layer: "TMAP_TERRAIN_HYBRID_MAP",
                        },
                    ],
                },
                "overviewMap",
            ],
        };
    },
    mounted() {},
    methods: {
        hideLayer() {
            var elements = document.querySelectorAll("div.tdt-layer");
            elements[0].style.display = "none";
            elements[1].style.display = "none";
        },
        addSingleLayer(layerid) {
            let tile = new T.TileLayer.WMS(
                "http://39.100.134.93:6080/arcgis/services/trafficresouce/MapServer/WmsServer",
                {
                    version: "1.3.0",
                    layers: layerid,
                    transparent: true,
                    styles: "",
                    crs: "EPSG:102100",
                    src: "",
                    format: "image/png",
                }
            );
            this.layers.push({
                id: layerid,
                layer: tile,
            });
            this.map.addLayer(tile);
        },
        addTileLayer(layerid, server, zindex) {
            let tilelayer = new T.TileLayer(
                "http://39.100.134.93:6080/arcgis/rest/services/" +
                    server +
                    "/MapServer/tile/{z}/{y}/{x}",
                {
                    bounds: [
                        [43.42554189908981, 121.52793266655377],
                        [53.479591833136936, 134.72562801559476],
                    ],
                    errorTileUrl: "../../assets/images/blank.png",
                    zIndex: zindex,
                }
            );
            this.layers.push({
                id: layerid,
                layer: tilelayer,
            });
            this.map.addLayer(tilelayer);
        },
        initLayer() {
            // this.hideLayer();
            this.addTileLayer(100101, "zc_gdgs1", 999);
            this.addTileLayer(100102, "zc_sdgs", 998);
            this.addTileLayer(100104, "zc_gd", 997);
            this.addTileLayer(100105, "zc_sd", 996);
            this.addTileLayer(100106, "zc_xd", 995);
            this.addTileLayer(100107, "zc_yd", 994);
            this.addTileLayer(100108, "zc_zygl1", 993);
        },
        mapInit(map) {
            this.map = map;
            this.initLayer();
        },
        checkLayer(layerid) {
            for (var i = 0; i < this.layers.length; i++) {
                if (this.layers[i].id == layerid) {
                    return false;
                }
            }
            return true;
        },
        addLayer(layerid) {
            if (this.checkLayer(layerid)) {
                this.addSingleLayer(layerid);
            }
        },
        removeLayer(layerid) {
            for (var i = 0; i < this.layers.length; i++) {
                if (this.layers[i].id == layerid) {
                    this.map.removeLayer(this.layers[i].layer);
                    this.layers.splice(i, 1);
                    return;
                }
            }
        },
    },
};
</script>

<style lang="" scoped></style>
